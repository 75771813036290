/**
* Generated automatically at built-time (2024-12-03T08:28:45.804Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "weinkuehlschrank-black-friday",templateKey: "sites/86-36e2a8f0-7685-45a4-88c8-f3dab8078965"};